<template>
  <div></div>
</template>

<script>
export default {
  name: "page-footer",
};
</script>

<style lang="scss" scoped></style>
