<template>
  <v-app-bar app dark class="navbar-style">
    <template v-slot:default>
      <div class="d-flex align-center">
        <router-link to="/" style="cursor: pointer">
          <v-img
            alt="Smart CRM Logo"
            class="shrink mr-2"
            contain
            src="./../assets/clear-logo.png"
            transition="scale-transition"
            width="65"
          />
        </router-link>
      </div>
      <v-spacer></v-spacer>
      <v-tabs v-if="isAuth" align-with-title>
        <v-tab
          v-for="item in menuItems"
          :key="item.title"
          :to="item.path"
          color="primary"
          router
        >
          <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-tab>
      </v-tabs>

      <v-tab color="primary" v-if="isAuth" @click="$emit('logout')">
        <v-icon>mdi-logout</v-icon>
        Logout
      </v-tab>
      <v-tab color="primary" v-else to="/login">
        <v-icon>mdi-login</v-icon>
        Login
      </v-tab>
    </template>
  </v-app-bar>
</template>

<script>
export default {
  name: "nav-bar",
  data() {
    return {
      appTitle: "SAF-T",
      sidebar: true,
      menuItems: [
        {
          title: "Dashboard",
          path: "/",
        },
        {
          title: "Parteneri",
          path: "/partners",
        },
        {
          title: "Clienti",
          path: "/clients",
        },
        {
          title: "Licente",
          path: "/licences",
        },
        {
          title: "Logs",
          path: "/logs",
        },
      ],
    };
  },
  props: {
    isAuth: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
