const module = {
  namespaced: true,

  state() {
    return {
      roles: null,
      loading: true,
      loggedIn: false,
      accessToken: null,
    };
  },
  getters: {
    isAuthenticated: (state) => {
      return state.loggedIn;
    },
    getRoles: (state) => {
      return state.roles;
    },
    getLoading: (state) => {
      return state.loading;
    },
  },
  mutations: {
    loginSuccess(state, data) {
      state.loggedIn = true;
      state.accessToken = data.token;
      state.roles = data.roles;
    },
    logout(state) {
      state.loggedIn = false;
      state.accessToken = null;
      state.roles = null;
    },
    loading_task_begin(state) {
      state.loading = true;
    },
    loading_task_end(state) {
      state.loading = false;
    },
  },
  actions: {
    logout({ commit }) {
      commit("logout");
    },
  },
};

export default module;
