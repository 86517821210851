import Vue from "vue";
import _ from "underscore";
import store from "../store";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "home" */ "../pages/views/HomePage.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../pages/views/LoginPage.vue"),
    meta: { requiresAuth: false },
  },
  {
    path: "/partners",
    name: "partner",
    component: () =>
      import(
        /* webpackChunkName: "partners" */ "../pages/views/PartnerPage.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/clients",
    name: "client",
    component: () =>
      import(/* webpackChunkName: "clients" */ "../pages/views/ClientPage.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/licences",
    name: "licence",
    component: () =>
      import(
        /* webpackChunkName: "licences" */ "../pages/views/LicencePage.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/logs",
    name: "logs",
    component: () =>
      import(/* webpackChunkName: "logs" */ "../pages/views/LogsPage.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach(async (to, _, next) => {
  store.commit("base-store/loading_task_begin");
  const publicPages = ["/", "/login", "/unauthorized"];

  if (
    !getIsAuthenticated() &&
    !publicPages.includes(to.path) &&
    to.meta?.requiresAuth
  ) {
    await importModule("login").then(() => {
      Vue.sessionStorage.set("redirectPath", to.name);
      next({ name: "login" });
    });
  }

  await importModule(to.name).then(() => {
    next();
  });
  function getIsAuthenticated() {
    return store.getters["base-store/isAuthenticated"];
  }
});

router.afterEach((_0, from) => {
  _.debounce(cleanupAfterRoute(from.name), 1500);
});

const importModule = async (moduleName) => {
  return await import(`@/store/modules/${moduleName}-module.js`)
    .then(async (module) => {
      if (await store.hasModule(moduleName)) {
        return;
      }
      return await store.registerModule(moduleName, module.default);
    })
    .catch(() => {
      // module has not been found, ignore it
      return;
    });
};

const cleanupAfterRoute = (moduleName) => {
  store.commit("base-store/loading_task_end");
  if (!moduleName || !store.hasModule(moduleName)) {
    return;
  }
  store.unregisterModule(moduleName);
};
export default router;
