import Vue from "vue";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import baseplugin from "../plugins/base-plugin";
import DefaultLayout from "../layouts/default-layout";
import { sessionStorage } from "../mixins/session-storage-mixins";
import Storage from "vue-ls";

let optionsSession = {
  namespace: "vuejs__",
  name: "sessionStorage",
  storage: "session",
};

// Mixins
Vue.mixin(sessionStorage);

// Plugins
Vue.use(baseplugin);
Vue.use(VueSweetalert2);
Vue.use(Object.assign({}, Storage), optionsSession);

// Components
Vue.component("default-layout", DefaultLayout);
