import Vue from "vue";
import store from "../store";
import instance from "../api/api-util";
import { Sweetalert2Icons } from "./constants";

const axiosHelper = {
  registerRequestAndResponseInterceptors() {
    instance.interceptors.request.use(
      (request) => {
        requestInterceptor(request);
        return request;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
    instance.interceptors.response.use((response) => {
      return response;
    }, responseErrorInterceptor.bind(this));

    function requestInterceptor(config) {
      config.headers["Cache-Control"] = "no-cache";
      const storeData = store.state["base-store"];
      if (getIsAuthenticated() && storeData.accessToken) {
        config.headers.Authorization = `Bearer ${storeData.accessToken}`;
      }
      return config;
      function getIsAuthenticated() {
        return store.getters["base-store/isAuthenticated"];
      }
    }
    function responseErrorInterceptor(error) {
      if (
        (!error.response || !error.response.data) &&
        error.message == "Network Error"
      ) {
        Vue.showSimpleDialog(
          Sweetalert2Icons.ERROR,
          "The session has been expired. The page will be refreshed in order to recreate it",
          "Session expired"
        ).then(() => {
          window.location.reload();
        });
        return Promise.reject(
          error && error.response && error.response.message
        );
      }

      const message = getObjectMessage(
        error.response.data.errors || error.response.data
      );
      switch (error.response.status) {
        case 400:
          Vue.showSimpleDialog(Sweetalert2Icons.ERROR, message, "");
          break;
        case 403:
          Vue.showSimpleDialog(
            Sweetalert2Icons.ERROR,
            "You do not enough permisions to perform this action",
            "Access denied"
          );
          break;
        case 404:
          Vue.showToast(Sweetalert2Icons.ERROR, message);
          break;
        case 500:
          Vue.showToast(
            Sweetalert2Icons.ERROR,
            `Unexpected error occcured, please contact the system administrator in order to solve the problem. Eroor: ${message}`
          );
          break;
        case 401:
          Vue.showSimpleDialog(
            Sweetalert2Icons.ERROR,
            "The session has been expired. The page will be refreshed in order to recreate it",
            "Session expired"
          ).then(() => {
            window.location.reload();
          });
          break;
      }
      return Promise.reject(error && error.response && error.response.message);

      function getObjectMessage(obj) {
        if (obj instanceof Array) {
          return obj.join("; ");
        } else if (typeof obj === "string" || obj instanceof String) {
          return obj;
        } else {
          var keys = Object.keys(obj);
          let result = getObjectMessage(obj[keys[0]]);
          for (let i = 1; i < keys.length; i++) {
            result += `;${getObjectMessage(obj[keys[i]])}`;
          }
          return result;
        }
      }
    }
  },
};

export default axiosHelper;
