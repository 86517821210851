<template>
  <default-layout>
    <router-view />
  </default-layout>
</template>

<script>
import { mapMutations } from "vuex";
import { SessionStorageKeys } from "../utils/constants.js";
export default {
  name: "App",
  methods: {
    ...mapMutations("base-store", {
      loginSuccessMutation: "loginSuccess",
    }),
  },
  created() {
    const token = this.getSessionStorageValue(SessionStorageKeys.TOKEN);
    if (token) {
      this.loginSuccessMutation(token);
    }
  },
};
</script>
