export const sessionStorage = {
  methods: {
    //TODO: Needs declaration
    getLocalStorageValue(key, defaultValue = null) {
      return this.$localStorage.get(key, defaultValue);
    },
    setLocalStorageValue(key, value) {
      this.$localStorage.set(key, value);
    },
    removeLocalStorageValue(key) {
      this.$localStorage.remove(key);
    },
    clearLocalStorage() {
      this.$localStorage.clear();
    },

    getSessionStorageValue(key, defaultValue = null) {
      return this.$sessionStorage.get(key, defaultValue);
    },
    setSessionStorageValue(key, value, expire = null) {
      this.$sessionStorage.set(key, value, expire);
    },
    removeSessionStorageValue(key) {
      this.$sessionStorage.remove(key);
    },
    clearSessionStorage() {
      this.$sessionStorage.clear();
    },
  },
};
