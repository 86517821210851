<template>
  <v-app transition="fade-transition">
    <NavBar :isAuth="isAuthenticatedGetter" @logout="logout" />
    <v-main
      transition="fade-transition"
      v-show="!getLoadingGetter"
      class="main"
    >
      <slot v-if="isAuthenticatedGetter || !requiresAuth" />
      <Unauthorized v-else />
    </v-main>
    <v-progress-circular
      transition="fade-transition"
      v-show="getLoadingGetter"
      class="main-loading"
      color="primary"
      indeterminate
      :size="80"
      :width="6"
    />
    <Footer />
  </v-app>
</template>

<script>
import NavBar from "../components/NavBar.vue";
import Footer from "../components/Footer.vue";
import { mapGetters, mapActions } from "vuex";
import Unauthorized from "../components/Unauthorized.vue";
export default {
  components: {
    Unauthorized,
    NavBar,
    Footer,
  },
  computed: {
    ...mapGetters("base-store", {
      isAuthenticatedGetter: "isAuthenticated",
      getLoadingGetter: "getLoading",
    }),
    requiresAuth() {
      return this.$route.meta?.requiresAuth ?? false;
    },
  },
  methods: {
    ...mapActions("base-store", {
      logoutAction: "logout",
    }),
    logout() {
      this.logoutAction().then(() => {
        this.clearSessionStorage();
        this.$router.push({ name: "login" });
      });
    },
  },
  created() {},
};
</script>

<style lang="scss">
@import "../assets/scss/default-layout.scss";
.main {
  padding-top: 100px;
}
.main-loading {
  transform: translate(-50%, 0);
  position: absolute;
  top: 120px;
  left: 50%;
}
</style>
