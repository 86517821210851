<template>
  <v-container fluid class="fill-height">
    <v-row justify="center">
      <v-card elevation="2">
        <v-card-title tag="p" class="text-h3 justify-center text-uppercase">
          Unauthorized
        </v-card-title>
        <v-card-text class="text-h4 text-center">
          Please login or contact your administrator to get access to this page.
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "unauthorized-page",
  data() {
    return {
      //
    };
  },
  computed: {
    //
  },
  methods: {
    //
  },
  async mounted() {
    //
  },
  created() {
    //
  },
};
</script>

<style lang="scss" scoped></style>
