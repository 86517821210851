export class Sweetalert2Icons {
  static SUCCESS = "success";
  static ERROR = "error";
  static WARNING = "warning";
  static INFO = "info";
  static QUESTION = "question";
}

export class SessionStorageKeys {
  static TOKEN = "token";
  static REDIRCT_PATH = "redirect_path";
}

export class RoleConstants {
  static ADMIN = 1;
  static EXECUTIVE = 2;
  static OPERATOR = 3;
}
